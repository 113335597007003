<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.baseConditionName"
            placeholder="请输入要查询的数据条件名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="checkTable()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          size="mini"
          class="iot-baseCondition-create"
          @click="addcommunicationProtocol()"
          >新增数据条件</el-button
        >
      </el-form>
    </div>
    <div style="height:678px;overflow-y: auto;">
       <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="baseConditionId" label="ID"></el-table-column>
      <el-table-column prop="baseConditionName" label="数据条件名称"></el-table-column>
      <el-table-column prop="remark" label="数据条件备注"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="warning"
            @click="edit(scope.row.baseConditionId)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="del(scope.row.baseConditionId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      
    </el-table>
    </div>
   
    <div class="page">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
        :total="total"
        :page-size="getForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination>
    </div>
   
    <el-dialog
    top="3%"
      title="填写表单"
      :show-close="false"
      :visible.sync="baseConditionVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="baseConditionForm"
        ref="subRef"
        :rules="rules"
        label-position="top"
      >
        <el-form-item label="数据条件名称" label-width="120" prop="baseConditionName">
          <el-input
            v-model="baseConditionForm.baseConditionName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="数据条件备注" label-width="120" prop="remark">
          <el-input
            v-model="baseConditionForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="baseConditionVisinbile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  name: "DataCondition",
  props: {
    msg: String,
  },
  data() {
    return {
      theme: { TABLE_HEADER_COLOR },
      tableData: [],
      getForm: {
        current: 1,
        size: 10,
        baseConditionName: "",
      },
      total: 0,
      baseConditionVisinbile: false,
      baseConditionForm: {
        baseConditionId: "",
        baseConditionName: "",
        remark: "",
      },
      isCreate: true,
      rules: {
        baseConditionName: [
          { required: true, message: "数据条件名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
   

    handleSizeChange(val) {
        that.getForm.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.getForm.current=val;
        that.getList();
      },
    getList: function () {
      that.$api.baseCondition
        .getListBase(that.getForm)
        .then((response) => {
       
          that.tableData = response.data.records;
          that.total = response.data.total;
        });
    },
    handleClose: function () {
      that.baseConditionVisinbile = false;
    },
    addcommunicationProtocol: function () {
      that.baseConditionForm= {
        baseConditionId: "",
        baseConditionName: "",
        remark: "",
      };
      that.baseConditionVisinbile = true;
      that.isCreate = true;
    },
    edit: function (id) {
      that.$api.baseCondition
        .getDataCondition(id)
        .then((response) => {
          
          that.baseConditionVisinbile = true;
          that.isCreate = false;
          that.baseConditionForm = response.data;
        });
    },
    del: function (id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.baseCondition
            .delDataCondition(id)
            .then(() => {
              that.getList();
              that.$message({
                message: "删除成功",
                type: "success",
              });
            });
        })
        .catch(() => {});
    },
    checkTable: function () {
      that.getList();
    },
    submitForm: function () {
      that.$refs["subRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.baseCondition.addDataCondition(
                that.baseConditionForm
              )
            : that.$api.baseCondition.editDataCondition(
                that.baseConditionForm
              );
          _api.then((response) => {
            if (response.code == 200) {
              that.baseConditionVisinbile = false;
              that.isCreate = true;
              that.getList();
              that.$message({
                type: "success",
                message: "请求成功",
              });
              that.$refs.subRef.resetFields();
            }
          });
        }
      });
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    that.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-baseCondition-create {
  float: right;
  margin-top: 10px;
}
.iot-baseCondition-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-person >>> .el-select {
  width: 100%;
}
.page{
  display: flex;
  justify-content: flex-end;
}
</style>
